import styled, { css } from 'styled-components';

import { Button, Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 450px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 30px;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 30px 0 8px 0;
  `};
`;
export const Content = styled(Typography)`
  ${({ theme }) => css`
    width: 95%;
    font-size: ${theme.typography.large.fontSize};
    white-space: normal;
    text-align: center;
  `};
`;
export const ConfirmButton = styled(Button)`
  ${({ theme, onClick }) => css`
    width: 100%;
    padding: 16px;
    margin-top: 30px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    font-weight: ${theme.typography.fontWeightBold};
    cursor: ${onClick ? 'pointer' : 'default'};
  `};
`;
