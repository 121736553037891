import React, { useEffect, useState } from 'react';

import { DateFilter, SelectFilter } from '@trader/components';
import { TPaymentStatus, TTransferType } from '@trader/api';
import { useI18next } from '@trader/services';
import { TDateFilterInterval } from '@trader/types';

import * as Styled from './styled';
import { useFilterOptions } from '../../../hooks/useFilterOptions';

interface IFilters {
  onFiltersChange: (
    statuses: TPaymentStatus[],
    transferTypes: TTransferType[],
    interval: TDateFilterInterval | null
  ) => void;
}

export const TransferFilters: React.FC<IFilters> = ({ onFiltersChange }) => {
  const { translate } = useI18next();
  const { selectStatusOptions, selectTransferTypeOptions } = useFilterOptions();

  const [selectedStatuses, setSelectedStatuses] = useState<TPaymentStatus[]>(
    []
  );
  const [selectedTransferType, setSelectedTransferType] = useState<
    TTransferType[]
  >([]);
  const [selectedInterval, setSelectedInterval] =
    useState<TDateFilterInterval | null>(null);

  useEffect(() => {
    onFiltersChange(selectedStatuses, selectedTransferType, selectedInterval);
  }, [selectedStatuses, selectedTransferType, selectedInterval]);

  return (
    <Styled.Root direction='row' spacing='16px'>
      <SelectFilter
        label={translate('COMMON.LABELS.STATUS')}
        options={selectStatusOptions}
        onChange={values => setSelectedStatuses(values as TPaymentStatus[])}
      />
      <SelectFilter
        label={translate('COMMON.LABELS.TYPE')}
        options={selectTransferTypeOptions}
        onChange={values => setSelectedTransferType(values as TTransferType[])}
      />
      <DateFilter onApply={setSelectedInterval} />
    </Styled.Root>
  );
};
