import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Summary = styled(Wrapper)<{ $profit?: number }>`
  ${({ theme, $profit }) => css`
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 12px 0;
    width: 200px;
    background-color: ${theme.palette.gray.light};

    ${conditionApply(
      [$profit && $profit > 0],
      css`
        background-color: ${theme.palette.green['600']};
      `
    )}

    ${conditionApply(
      [$profit && $profit < 0],
      css`
        background-color: ${theme.palette.red['300']};
      `
    )}

    p:nth-child(1) {
      font-size: ${theme.typography.xl.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
      margin-bottom: 20px;
    }

    p:nth-child(2) {
      font-size: 1.25rem;
      font-weight: 600;

      ${conditionApply(
        [$profit && $profit > 0],
        css`
          color: ${theme.palette.green.dark};
        `
      )}

      ${conditionApply(
        [$profit && $profit < 0],
        css`
          color: ${theme.palette.red.dark};
        `
      )}
    }
  `}
`;
