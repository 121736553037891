import { getRoot, IAnyStateTreeNode, Instance, types } from 'mobx-state-tree';

import { getDateInterval, runInAction } from '@trader/utils';
import {
  IBackTestingMultiplierFinished,
  IBackTestingPosition,
  IBackTestingSummary,
  IMultiplierData,
  IOption,
} from '@trader/types';

import {
  cancelBackTestRunningAsync,
  existsBackTestAsync,
  startBackTestingAsync,
} from './actions';
import {
  highMultiplier,
  lowMultiplier,
  multiplierStep,
} from '@trader/constants';

export const backTestingModel = types
  .model('backTestingModel', {
    id: types.maybeNull(types.number),
    isOpen: false,
    isPending: false,
    quantity: types.number,
    lowestMultiplier: types.number,
    highestMultiplier: types.number,
    multiplierStep: types.number,
    sl: types.number,
    percentFinished: types.number,
    oneTradePerSession: false,
    allowAveraging: false,
    from: types.Date,
    to: types.Date,
    sessionFrom: types.maybeNull(types.Date),
    sessionTo: types.maybeNull(types.Date),
    selectedMultiplierOption: types.frozen<IOption | null>(null),
    selectedBandOption: types.frozen<IOption | null>(null),
    summary: types.frozen<IBackTestingSummary | null>(null),
    multipliers: types.frozen<
      IBackTestingMultiplierFinished['bandMultipliersData']
    >([]),

    startBackTestingAsync,
    cancelBackTestRunningAsync,
    existsBackTestAsync,
  })
  .views(store => ({
    getSymbol: (): string => {
      const root = getRoot(store) as IAnyStateTreeNode;

      const tradingSymbol = root.pages.trading.getInstrumentSymbolByLayout();
      const muliBandsSymbol = root.pages.muliBands.symbol;

      return muliBandsSymbol || tradingSymbol;
    },
    getListOfTrades: (): Array<
      IBackTestingPosition & { multiplier?: number }
    > => {
      if (!store.selectedMultiplierOption) {
        return [];
      }
      const selectedMultiplier = Number(store.selectedMultiplierOption.value);

      const allMultipliers = getUniqueMultipliers(store.multipliers).filter(
        m => m.multiplier === selectedMultiplier
      );

      const allPositions = allMultipliers
        .map(m =>
          m.positions.map(position => ({
            ...position,
            multiplier: m.multiplier,
          }))
        )
        .flat();

      if (store.selectedBandOption) {
        const selectedBand = Number(store.selectedBandOption.value);

        const allPositionsByBand = allMultipliers.find(
          m => m.bandId === selectedBand
        );

        return allPositionsByBand?.positions || [];
      }

      return allPositions;
    },
    getListOfMultipliers: () => {
      const uniqueMultipliers = getUniqueMultipliers(store.multipliers).map(
        m => ({
          ...m,
          positions: m.positions.map(position => ({
            ...position,
            multiplier: m.multiplier,
          })),
        })
      );

      if (!store.selectedMultiplierOption) {
        return uniqueMultipliers;
      }

      const selectedMultiplier = Number(store.selectedMultiplierOption.value);

      const filteredMultipliers = uniqueMultipliers.filter(
        m => m.multiplier === selectedMultiplier
      );

      if (store.selectedBandOption) {
        const selectedBand = Number(store.selectedBandOption.value);

        const multiplierByBand = filteredMultipliers.find(
          m => m.bandId === selectedBand
        );

        return multiplierByBand ? [multiplierByBand] : [];
      }

      return filteredMultipliers;
    },
    getAllMultipliers: () => getUniqueMultipliers(store.multipliers),
    getMultiplier: () => {
      if (!store.selectedMultiplierOption || !store.selectedBandOption) {
        return null;
      }

      const uniqueMultipliers = getUniqueMultipliers(store.multipliers);

      const selectedMultiplier = Number(store.selectedMultiplierOption.value);
      const selectedBand = Number(store.selectedBandOption.value);

      return uniqueMultipliers.find(
        m => m.multiplier === selectedMultiplier && m.bandId === selectedBand
      );
    },
  }))
  .actions(store => ({
    runInAction,
    toggleBackTest: () => {
      store.isOpen = !store.isOpen;
    },
    clear: () => {
      store.id = null;
      store.isOpen = false;
      store.isPending = false;
      store.oneTradePerSession = false;
      store.allowAveraging = false;
      store.selectedMultiplierOption = null;
      store.selectedBandOption = null;
      store.quantity = 0;
      store.sl = 0;
      store.percentFinished = 0;
      store.lowestMultiplier = lowMultiplier;
      store.highestMultiplier = highMultiplier;
      store.multiplierStep = multiplierStep;
      store.from = getDateInterval('prevDay').from;
      store.to = getDateInterval('prevDay').to;
      store.sessionFrom = null;
      store.sessionTo = null;
      store.summary = null;
      store.multipliers = [];
    },
    setSummary: (summary: IBackTestingSummary) => {
      store.summary = summary;
      store.isPending = false;
      store.percentFinished = 100;
    },
    addMultiplier: ({
      bandMultipliersData,
    }: IBackTestingMultiplierFinished) => {
      store.multipliers = [...store.multipliers, ...bandMultipliersData];
      store.percentFinished = bandMultipliersData[0].percentFinished;
    },
  }));

export const backTesting = types.optional(backTestingModel, {
  id: null,
  selectedMultiplierOption: null,
  selectedBandOption: null,
  sl: 0,
  quantity: 0,
  percentFinished: 0,
  lowestMultiplier: lowMultiplier,
  highestMultiplier: highMultiplier,
  multiplierStep: multiplierStep,
  ...getDateInterval('prevDay'),
});

const getUniqueMultipliers = (
  multipliers: IBackTestingMultiplierFinished['bandMultipliersData']
): Array<IMultiplierData> => {
  const newMap = new Map<string, IMultiplierData>();

  multipliers.forEach(m => {
    const key = `${m.multiplier}-${m.bandId}`;
    newMap.set(key, {
      ...m,
      positions: m.positions.map(position => ({
        ...position,
        multiplier: m.multiplier,
      })),
    });
  });

  return Array.from(newMap.values());
};

export type TBackTestingStore = Instance<typeof backTestingModel>;
