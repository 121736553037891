import styled, { css } from 'styled-components';
import { Wrapper, Typography, Button } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 451px;
    height: 373px;
    padding: 35px 36px 0 36px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Title = styled(Wrapper)`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    > svg {
      width: 40px;
      height: 40px;
    }

    > svg:nth-child(2) {
      margin: 0 15px;
    }
  `}
`;

export const Content = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    width: 100%;
    margin-bottom: 40px;
    margin-top: 38px;
  `};
`;

export const Body = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-top: 50px;
    justify-content: space-between;

    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

interface IButton {
  $isFailed: boolean;
}

export const Cta = styled(Button)<IButton>`
  ${({ theme, $isFailed, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.common.white};
    background: ${theme.palette.primary.dark};

    ${conditionApply(
      [disabled],
      css`
        color: ${theme.palette.common.white} !important;
      `
    )}

    ${conditionApply(
      [$isFailed],
      css`
        background: transparent;
        color: ${theme.palette.text.secondary};
        border: 1px solid ${theme.palette.primary.dark};
      `
    )}

    &:hover {
      background: ${$isFailed ? 'transparent' : theme.palette.primary.dark};
    }

    > span {
      text-transform: capitalize;
    }
  `};
`;
