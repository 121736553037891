import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { ICloseAllPositionsParams } from '@trader/api';
import { useMst } from '@trader/store';
import {
  Icon,
  ProfitValue,
  RadioGroup,
  Tooltip,
  Typography,
  Wrapper,
} from '@trader/components';
import { calcAllPositionsPl } from '@trader/utils';

import { useFilteredPositions } from './useFilteredPositions';
import * as Styled from './styled';

export const CloseAllOptionsSelection: React.FC<{ onClose: () => void }> =
  observer(({ onClose }) => {
    const { translate } = useI18next();
    const [selectedOption, setSelectedOption] =
      useState<ICloseAllPositionsParams['close']>('All');

    const store = useMst();
    const isPositionsClosing =
      store.entities.positionsMetrics.closeAllPositionsAsync.inProgress;

    const {
      allPositions,
      profitablePositions,
      losingPositions,
      buyPositions,
      sellPositions,
    } = useFilteredPositions();

    const handleConfirmButtonClick = async () => {
      await store.entities.positionsMetrics.closeAllPositionsAsync.run({
        close: selectedOption,
      });
      onClose();
    };

    return (
      <Styled.Root>
        <Styled.ContentHeader>
          <Typography>
            {translate('COMMON.LABELS.CLOSE_ALL_MODAL_TITLE')}
          </Typography>
          <Wrapper height='21px'>
            <Tooltip title='Certain positions may not be available for closing when market is closed.'>
              <Icon iconType='info' style={{ marginLeft: '5px' }} />
            </Tooltip>
          </Wrapper>
        </Styled.ContentHeader>
        <Styled.Content>
          <RadioGroup
            items={[
              {
                label: (
                  <OptionLabel
                    text={translate('COMMON.LABELS.CLOSE_ALL')}
                    count={allPositions.length}
                    pl={calcAllPositionsPl(allPositions, { withoutSwap: true })}
                  />
                ),
                disabled: !allPositions.length,
                value: 'All',
              },
              {
                label: (
                  <OptionLabel
                    text={translate('COMMON.LABELS.CLOSE_ALL_PROFITABLE')}
                    count={profitablePositions.length}
                    pl={calcAllPositionsPl(profitablePositions, {
                      withoutSwap: true,
                    })}
                  />
                ),
                disabled: !profitablePositions.length,
                value: 'Profit',
              },
              {
                label: (
                  <OptionLabel
                    text={translate('COMMON.LABELS.CLOSE_ALL_LOSING')}
                    count={losingPositions.length}
                    pl={calcAllPositionsPl(losingPositions, {
                      withoutSwap: true,
                    })}
                  />
                ),
                disabled: !losingPositions.length,
                value: 'Loss',
              },
              {
                label: (
                  <OptionLabel
                    text={translate('COMMON.LABELS.CLOSE_ALL_BUY')}
                    count={buyPositions.length}
                    pl={calcAllPositionsPl(buyPositions, { withoutSwap: true })}
                  />
                ),
                disabled: !buyPositions.length,
                value: 'Buy',
              },
              {
                label: (
                  <OptionLabel
                    text={translate('COMMON.LABELS.CLOSE_ALL_SELL')}
                    count={sellPositions.length}
                    pl={calcAllPositionsPl(sellPositions, {
                      withoutSwap: true,
                    })}
                  />
                ),
                disabled: !sellPositions.length,
                value: 'Sell',
              },
            ]}
            direction='vertical'
            value={selectedOption}
            onChange={e =>
              setSelectedOption(
                e.target.value as ICloseAllPositionsParams['close']
              )
            }
            shouldHideControlInfo
          />
          <Wrapper padding='8px 0 16px 0'>
            <Styled.CancelButton fullWidth onClick={onClose}>
              {translate('COMMON.LABELS.CANCEL')}
            </Styled.CancelButton>
            <Styled.SubmitButton
              fullWidth
              onClick={handleConfirmButtonClick}
              disabled={isPositionsClosing || !allPositions.length}
              loading={isPositionsClosing}
            >
              {translate('COMMON.LABELS.CONFIRM')}
            </Styled.SubmitButton>
          </Wrapper>
        </Styled.Content>
      </Styled.Root>
    );
  });

const OptionLabel: React.FC<{ text: string; count: number; pl: number }> = ({
  text,
  count,
  pl,
}) => {
  return (
    <Wrapper justifyContent='space-between'>
      <Wrapper alignItems='center'>
        <Typography variant='medium' display='block'>
          {text}
        </Typography>
        <Styled.OptionCount>{count}</Styled.OptionCount>
      </Wrapper>
      <ProfitValue value={+pl.toFixed(2)} />
    </Wrapper>
  );
};
