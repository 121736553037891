import { useState } from 'react';

import { createDynamicComponent } from '@trader/utils';
import { useI18next } from '@trader/services';
import { Wrapper } from '@trader/components';

import { BackTestConfig } from './components/backTestConfig';
import { ListOfMultipliers } from './components/listOfMultipliers';
import { ListOfTrades } from './components/listOfTrades';
import { PerformanceSummary } from './components/performanceSummary';
import { LoadingProgressBar } from './components/loadingProgressBar';
import * as Styled from './styled';

type TSubTabs =
  | 'backTestConfig'
  | 'listOfMultiplies'
  | 'listOfTrades'
  | 'performanceSummary';

export const BackTestStrategyTab = () => {
  const { translate } = useI18next();
  const [selectedSubTab, setSelectedSubTab] =
    useState<TSubTabs>('backTestConfig');

  const subTabs = {
    backTestConfig: BackTestConfig,
    listOfMultiplies: ListOfMultipliers,
    listOfTrades: ListOfTrades,
    performanceSummary: PerformanceSummary,
  };

  return (
    <div>
      <Styled.SubTabs>
        <Wrapper>
          <Styled.SubTab
            onClick={() => setSelectedSubTab('backTestConfig')}
            $selected={selectedSubTab === 'backTestConfig'}
          >
            {translate('MULI_BANDS.BACK_TEST_CONFIG')}
          </Styled.SubTab>
          <Styled.Divider />
          <Styled.SubTab
            onClick={() => setSelectedSubTab('listOfMultiplies')}
            $selected={selectedSubTab === 'listOfMultiplies'}
          >
            {translate('MULI_BANDS.LIST_OF_MULTIPLIERS')}
          </Styled.SubTab>
          <Styled.Divider />
          <Styled.SubTab
            onClick={() => setSelectedSubTab('listOfTrades')}
            $selected={selectedSubTab === 'listOfTrades'}
          >
            {translate('MULI_BANDS.LIST_OF_TRADES')}
          </Styled.SubTab>
          <Styled.Divider />
          <Styled.SubTab
            onClick={() => setSelectedSubTab('performanceSummary')}
            $selected={selectedSubTab === 'performanceSummary'}
          >
            {translate('MULI_BANDS.PERFORMANCE_SUMMARY')}
          </Styled.SubTab>
        </Wrapper>
        <LoadingProgressBar />
      </Styled.SubTabs>
      <Styled.Content>
        {createDynamicComponent(subTabs[selectedSubTab], {})}
      </Styled.Content>
    </div>
  );
};
