import styled, { css } from 'styled-components';
import { Button, Wrapper } from '@trader/components';
import { muliBandsPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    padding: ${muliBandsPadding}px;
  `};
`;

export const ApplyChanges = styled(Button)`
  ${({ theme }) => css`
    width: 158px;
    background: ${theme.palette.primary.dark};
    color: ${theme.palette.common.white};
    font-size: ${theme.typography.large.fontSize};
    padding: 0 10px;
    height: 45px;
    margin-top: 16px;

    :hover {
      background: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
    }
  `};
`;

export const MainInputs = styled(Wrapper)`
  ${() => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  `};
`;
