import styled, { css } from 'styled-components';

import { Button, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .assets {
      height: 100%;
      margin-top: 0;

      > button {
        display: none;
      }
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;

export const Body = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
  `}
`;

export const Action = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: center;

    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

export const ActionButton = styled(Button)<{
  $isTransparent: boolean;
}>`
  ${({ theme, $isTransparent, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 29px;
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.white.main};
    background: ${theme.palette.purchaseButtons.green};

    ${conditionApply(
      [$isTransparent],
      css`
        background: transparent;
        color: ${theme.palette.text.secondary};
        width: 145px;
        border: 1px solid ${theme.palette.primary.dark};
      `
    )}
    ${conditionApply(
      [$isTransparent && disabled],
      css`
        background: transparent !important;
        border: 1px solid ${theme.palette.gray.light};
      `
    )}
    &:hover {
      background: ${$isTransparent
        ? 'transparent'
        : theme.palette.purchaseButtons.green};
    }
  `};
`;
