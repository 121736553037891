import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IPurchasesHistoryBE,
  IPurchaseHistoryBE,
  TPaymentStatus,
} from '@trader/api';
import {
  EmptyData,
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  ScrollingList,
} from '@trader/components';
import { useMst } from '@trader/store';
import { dateFormats } from '@trader/utils';
import { useI18next } from '@trader/services';

import { PurchaseHistoryFilters } from './filters';
import * as Styled from './styled';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  purchases: IPurchasesHistoryBE['purchases'];
  totalCount: number;
}

export const PurchaseHistory: React.FC = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();

  const [state, setState] = useState<IState>({
    isFetchingMore: false,
    isLoading: true,
    purchases: [],
    totalCount: 0,
  });

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  const fetchPurchaseHistory = async (statuses?: TPaymentStatus[]) => {
    !state.isLoading && setState(prev => ({ ...prev, isLoading: true }));
    const response = await store.user.getPurchaseHistoryAsync.run({ statuses });
    if (response) {
      setState(prev => ({
        ...prev,
        totalCount: response.totalCount,
        isLoading: false,
        purchases: response.purchases,
      }));
    }
  };

  const fetchMoreHistory = async (pageNumber: number) => {
    setState(prev => ({ ...prev, isFetchingMore: true }));
    const response = await store.user.getPurchaseHistoryAsync.run({
      pageNumber,
    });
    if (response) {
      setState(prev => ({
        ...prev,
        purchases: [...prev.purchases, ...response.purchases],
      }));
    }
  };

  const renderRowItemsCallBacks: IRenderRowItemsCallBacks<IPurchaseHistoryBE> =
    useMemo(() => {
      const formatDate = 1000;
      const typeTranslationMap: Record<IPurchaseHistoryBE['type'], string> = {
        Payment: translate('COMMON.LABELS.PAYMENT'),
        Payout: translate('COMMON.LABELS.PAYOUT'),
      };
      const statusTranslationMap: Record<IPurchaseHistoryBE['status'], string> =
        {
          Success: translate('COMMON.LABELS.SUCCESS'),
          Failure: translate('COMMON.LABELS.FAILURE'),
          Pending: translate('COMMON.LABELS.PENDING'),
          Canceled: translate('COMMON.LABELS.CANCELED'),
          TimeOut: translate('COMMON.LABELS.TIME_OUT'),
        };

      return {
        id: item => item.value,
        value: item => item.value,
        status: item =>
          statusTranslationMap[item.value as IPurchaseHistoryBE['status']],
        type: item =>
          typeTranslationMap[item.value as IPurchaseHistoryBE['type']],
        comment: item => item.value,
        'date.seconds': item =>
          dateFormats(
            new Date((item?.value as number) * formatDate),
            'Mm dd, yyyy',
            currentLng
          ),
      };
    }, [currentLng]);

  const cells: Array<IHeadCell> = [
    {
      id: 'id',
      label: translate('COMMON.LABELS.PURCHASED_ID'),
      minWidth: 40,
      align: 'start',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.AMOUNT'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'status',
      label: translate('COMMON.LABELS.STATUS'),
      minWidth: 70,
      align: 'center',
    },
    {
      id: 'type',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: 70,
      align: 'start',
    },
    {
      id: 'date.seconds',
      label: translate('COMMON.LABELS.DATE'),
      minWidth: 120,
      align: 'start',
    },
    {
      id: 'comment',
      label: translate('COMMON.LABELS.COMMENT'),
      minWidth: 180,
      align: 'start',
    },
  ];

  const renderHistoryList = () => {
    if (
      !state.isFetchingMore &&
      store.user.getPurchaseHistoryAsync.inProgress
    ) {
      return <Progress />;
    }

    if (!state.purchases.length && !state.isLoading) {
      return <EmptyData title={translate('COMMON.LABELS.NO_HISTORY')} />;
    }

    return (
      <ScrollingList<IPurchaseHistoryBE>
        rows={state.purchases}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCallBacks}
        totalCount={state.totalCount}
        fetchMore={fetchMoreHistory}
      />
    );
  };

  return (
    <Styled.Root>
      <PurchaseHistoryFilters onFiltersChange={fetchPurchaseHistory} />
      {renderHistoryList()}
    </Styled.Root>
  );
});
