import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '@trader/components';
import { useNavigation, useWindowDimensions } from '@trader/hooks';
import {
  calculateSpread,
  formatByPipSize,
  getInstrumentDifference,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { breakpoints } from '@trader/themes';
import { useI18next } from '@trader/services';
import {
  NAVIGATE_TO,
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';

import { LineChart } from '../../lineChart';
import * as Styled from './styled';

interface IInstrument {
  symbol: string;
  miniChartCoordinates: Array<number>;
}

export const Instrument: React.FC<IInstrument> = observer(
  ({ symbol, miniChartCoordinates }) => {
    const { width } = useWindowDimensions();
    const store = useMst();
    const { translate } = useI18next();
    const { navigateTo } = useNavigation();

    const trading = store.trading.getTrading('createOrder');
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    const selectedSymbol = store.pages.trading.getInstrumentSymbolByLayout();

    const breakpointSm = breakpoints?.values?.sm as number;
    const shouldDisplay = width > breakpointSm;

    const isNextButtonDisabled =
      store.pages.trading.getPrefetchInformationAsync.inProgress;

    const selectInstrument = () => {
      store.pages.trading.layout.selectInstrument(symbol);
      if (store.app.isBetaDesignEnabled()) {
        trading.getInstrumentReferenceAsync.run({
          symbol,
          side: trading.side,
        });
      }
    };

    const navigateToTrading = (event: SyntheticEvent) => {
      event.stopPropagation();
      selectInstrument();
      navigateTo(NAVIGATE_TO.main.trading);
    };

    return (
      <Styled.Box
        id={`instrument-${symbol}`}
        onClick={selectInstrument}
        $isSelected={symbol === selectedSymbol}
      >
        <Styled.InstrumentInfo
          $applyBetaSizing={store.app.isBetaDesignEnabled()}
        >
          <Tooltip title={symbol}>
            <Styled.Symbol>{symbol || ''}</Styled.Symbol>
          </Tooltip>
          {instrument?.tradingAvailability?.isUnavailable && (
            <Tooltip
              maxWidth={215}
              title={
                instrument.tradingAvailability.isHoliday
                  ? translate('TOOLTIPS.INSTRUMENT_CLOSED_DUE_TO_DAY_OFF')
                  : translate('COMMON.LABELS.MARKET_IS_CLOSED', {
                      openIn: instrument.tradingAvailability.openIn,
                    })
              }
            >
              <Styled.TradingDisabledIcon iconType='notAllowed' />
            </Tooltip>
          )}
        </Styled.InstrumentInfo>
        {store.ui.sidebar.leftBar.includedColumns.sell && (
          <Ask symbol={symbol} />
        )}
        {store.ui.sidebar.leftBar.includedColumns.buy && (
          <Bid symbol={symbol} />
        )}
        {shouldDisplaySpreadColumnOnInstrumentList &&
          store.ui.sidebar.leftBar.includedColumns.spread && (
            <Spread symbol={symbol} />
          )}
        {shouldDisplayChangeColumnOnInstrumentList &&
          store.ui.sidebar.leftBar.includedColumns.change && (
            <Change symbol={symbol} />
          )}
        {shouldDisplay &&
          store.ui.sidebar.leftBar.includedColumns.dailyTrend && (
            <Styled.InstrumentCharts>
              <LineChart data={miniChartCoordinates} />
            </Styled.InstrumentCharts>
          )}
        {store.ui.sidebar.leftBar.includedColumns.favourite && (
          <Favorite symbol={symbol} />
        )}
        <Styled.NavigateButton
          disabled={isNextButtonDisabled}
          iconType='arrowRight'
          onClick={navigateToTrading}
        />
      </Styled.Box>
    );
  }
);

interface IRealTimeComponent {
  symbol: string;
}

const Ask: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Price
      onAnimationEnd={() => {
        store.entities.instruments.update(symbol, {
          updateAskType: 'none',
        });
      }}
      $isAsk={true}
      $updateType={instrument?.updateAskType}
      $applyBetaSizing={store.app.isBetaDesignEnabled()}
    >
      {formatByPipSize(instrument?.ask || 0, instrument?.pipSize || 0)}
    </Styled.Price>
  );
});

const Bid: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Price
      onAnimationEnd={() => {
        store.entities.instruments.update(symbol, {
          updateBidType: 'none',
        });
      }}
      $isAsk={true}
      $updateType={instrument?.updateBidType}
    >
      {formatByPipSize(instrument?.bid || 0, instrument?.pipSize || 0)}
    </Styled.Price>
  );
});

const Spread: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Spread>
      {calculateSpread({
        ask: Number(instrument?.ask || 0),
        bid: Number(instrument?.bid || 0),
        pipSize: instrument?.pipSize || 0,
      })}
    </Styled.Spread>
  );
});

const Change: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const { isGrow, openCloseDifferenceInPercent } = getInstrumentDifference(
    instrument?.ask,
    instrument?.close,
    instrument?.pipSize
  );

  return (
    <Styled.Change $isAboveZero={isGrow}>
      {openCloseDifferenceInPercent}%
    </Styled.Change>
  );
});

const Favorite: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Favourite
      $applyBetaSizing={store.app.isBetaDesignEnabled()}
      $hideOnDesktop={!store.app.isBetaDesignEnabled()}
    >
      {instrument?.isFavorite ? (
        <Styled.Button
          onClick={() =>
            instrument.removeInstrumentFromFavouriteAsync.run(instrument.symbol)
          }
          size='medium'
          iconType='favoriteOn'
        />
      ) : (
        <Styled.Button
          onClick={() =>
            instrument?.addInstrumentToFavouriteAsync.run(instrument?.symbol)
          }
          size='medium'
          iconType='favouriteOff'
        />
      )}
    </Styled.Favourite>
  );
});
