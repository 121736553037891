import styled, { css, keyframes } from 'styled-components';

import { Typography, Wrapper, Icon } from '@trader/components';
import { conditionApply } from '@trader/utils';

import { IconButton } from '../../iconButton';

interface IBox {
  $isSelected: boolean;
}

export const Box = styled(Wrapper)<IBox>`
  ${({ theme, $isSelected }) => css`
    display: flex;
    width: 100%;
    height: 47px;
    align-items: center;
    padding: 8px 11px;
    border-bottom: 1px dashed ${theme.palette.background.default};
    cursor: pointer;
    transition: background-color ease-in-out 0.1s;

    &:hover {
      background: ${theme.palette.primary[100]};
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${conditionApply(
      [$isSelected],
      css`
        background: ${theme.palette.primary[100]};
      `
    )}
  `};
`;

export const InstrumentInfo = styled(Wrapper)<{ $applyBetaSizing: boolean }>`
  ${({ $applyBetaSizing }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72px;
    margin-right: 6px;

    div:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    ${conditionApply(
      [$applyBetaSizing],
      css`
        min-width: 55px;
        max-width: 55px;
      `
    )};
  `};
`;

export const InstrumentCharts = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    min-width: 50px;
    max-width: 50px;
    height: 100%;
  `};
`;

export const Favourite = styled(Wrapper)<{
  $hideOnDesktop: boolean;
  $applyBetaSizing: boolean;
}>`
  ${({ theme, $hideOnDesktop, $applyBetaSizing }) => css`
    display: flex;
    flex-direction: column;

    ${conditionApply(
      [$hideOnDesktop],
      css`
        ${theme.breakpoints.up('sm')} {
          display: none;
        }
      `
    )}

    ${conditionApply(
      [$applyBetaSizing],
      css`
        min-width: 35px;
        max-width: 35px;
      `
    )};
  `};
`;

interface IDifference {
  $isAboveZero: boolean;
}

export const Change = styled(Typography)<IDifference>`
  ${({ theme, $isAboveZero }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    flex: 1;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
    color: ${$isAboveZero
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
  `};
`;

export const Button = styled(IconButton)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.common.black};

    svg {
      width: 18px;
      height: 18px;
    }
  `};
`;

export const NavigateButton = styled(IconButton)`
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `};
`;

export const Symbol = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.primary.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `};
`;

interface IPrice {
  $updateType?: string;
  $isAsk?: boolean;
  $applyBetaSizing?: boolean;
}

const increaseAnimation = keyframes`
  0% {
    background-color: rgba(85, 255, 0, 0.71)
  }
  60% {
    background-color: rgba(85, 255, 0, 0.71);
  }
  100% {
    background-color: transparent;
  }
`;

const decreaseAnimation = keyframes`
  0% {
    background-color: rgba(255, 0, 0, 0.77);
  }

  60% {
    background-color: rgba(255, 0, 0, 0.77);
  }

  100% {
    background-color: transparent;
  }
`;

export const Price = styled('span')<IPrice>`
  ${({ $updateType, $isAsk, $applyBetaSizing, theme }) => css`
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.primary.dark};
    flex: 1;
    min-width: 65px;
    text-align: center;
    padding: 3px 0;
    margin-right: ${$isAsk ? '5' : '0'}px;
    border-radius: 5px;

    ${conditionApply(
      [$updateType === 'down'],
      css`
        animation: ${decreaseAnimation} 0.8s;
      `
    )}

    ${conditionApply(
      [$updateType === 'up'],
      css`
        animation: ${increaseAnimation} 0.8s;
      `
    )}

    ${conditionApply(
      [$applyBetaSizing],
      css`
        max-width: 65px;
      `
    )};
  `};
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.primary.dark};
    flex: 1;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
    padding: 3px 0;
    border-radius: 5px;
  `};
`;

export const TradingDisabledIcon = styled(Icon)`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    margin-left: 4px;

    circle {
      stroke: ${theme.palette.gray['800']};
    }

    path {
      stroke: ${theme.palette.gray['800']};
    }
  `};
`;
