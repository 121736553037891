import { IThemeOptions } from '@trader/themes';

export const darkTheme: IThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      light: 'rgba(38, 181, 115, 1)',
      main: 'rgba(38, 181, 115, 1)',
      dark: '#FFF',
      100: '#131522',
    },
    blue: {
      light: '#F1F8FF',
      main: '#005C97',
      dark: '#005C97',
      100: '#798CA4',
      200: '#6983E8',
      300: '#2B78AD',
      400: 'rgba(108, 115, 144, 1)',
      500: 'rgba(66, 69, 86, 1)',
    },
    gray: {
      light: '#999999',
      main: '#CECECE',
      dark: '#999999',
      100: '#B5B5B5',
      200: '#DBDBDB',
      300: '#1C1F2C',
      400: '#878787',
      500: '#363843',
      600: '#363843',
      700: '#848484',
      800: '#A8AAB5',
    },
    white: {
      main: '#fff',
    },
    black: {
      main: '#212121',
      light: '#131522',
      100: '#252939',
    },
    green: {
      light: 'rgb(235 245 210 / 11%)',
      main: '#B8DD28',
      dark: '#26A69A',
      100: '#B8DD28',
      200: '#CCEBE8',
      300: '#384941',
      400: '#26B573',
      500: '#AFF0D2',
      600: '#384941',
      700: '#E9FEFA',
      800: '#08C99D',
    },
    red: {
      light: '#FF5050',
      main: '#F73F4A',
      dark: '#F73F4A',
      100: 'rgba(231,88,88,0.47)',
      200: '#F7DFE1',
      300: '#FFF4F4',
    },
    yellow: {
      light: '#F9EAD7',
      main: '#F7931A',
      dark: '#ab630e',
      100: '#FEDF3E',
    },
    aqua: {
      light: '#DBE3EB',
      main: '#DBE3EB',
      dark: '#DBE3EB',
      100: '#E8E8E8',
      200: '#E4F4FF',
    },
    purple: {
      light: '#F1E7F9',
      main: '#A259DB',
    },
    text: {
      primary: '#FFF',
      secondary: '#e5e8ec',
      disabled: '#e5e8ec',
    },
    common: {
      black: '#FFF',
      white: '#212121',
    },
    background: {
      default: '#2E303F',
      paper: '#1C1F2C',
    },
    menu: {
      main: '#11131F',
    },
    chartMenu: {
      main: '#BBBBBB',
      disabled: '#FFFFFF',
    },
    tab: {
      main: '#F1F1F1',
      light: '#303030',
      disabled: '#4F5364',
    },
    purchaseButtons: {
      green: '#26A69A',
      red: '#F73F4A',
    },
    inputField: {
      background: '#313444',
      border: '#42475D',
      text: '#FFFFFF',
      disabledBg: '#999999',
      disabledText: '#CECECE',
      label: '#212121',
      placeholder: '#878787',
    },
    boxShadow: {
      main: '0px 0px 10px 0px #0000001A',
    },
  },
};
