import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { CircularProgressBar } from '@trader/components';

export const LoadingProgressBar = observer(() => {
  const theme = useTheme();
  const store = useMst();

  const percentFinished =
    +store.pages.muliBands.backTesting.percentFinished.toFixed();

  return (
    <CircularProgressBar
      pathColor={theme.palette.primary.main}
      value={percentFinished}
      text={`${percentFinished}%`}
    />
  );
});
