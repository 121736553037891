import styled, { css } from 'styled-components';

import { Button, Wrapper } from '@trader/components';

export const Trigger = styled('span')`
  ${({ theme }) => css`
    font-size: ${theme.typography.tiny.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.red.light};
    cursor: pointer;
    user-select: none;
  `}
`;

export const Root = styled(Wrapper)`
  ${() => css`
    width: 335px;
    flex-direction: column;
  `}
`;

export const Content = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 0 12px;
    flex-direction: column;
  `}
`;

export const ContentHeader = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid ${theme.palette.gray.light};

    p {
      font-size: ${theme.typography.medium.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }
  `}
`;

export const CancelButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};
    padding: 10px;
    border: 1px solid ${theme.palette.common.black};
    margin-right: 8px;

    &:hover {
      background-color: ${theme.palette.common.white};
    }
  `}
`;

export const SubmitButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.black};
    padding: 10px;
  `}
`;

export const OptionCount = styled('div')`
  ${({ theme }) => css`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${theme.palette.gray.light};
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-align: center;
    margin-left: 5px;
  `}
`;
