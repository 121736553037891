import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  EStripName,
  IBackTestingPosition,
  IMultiplierData,
} from '@trader/types';
import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  ScrollingList,
} from '@trader/components';
import { useMst } from '@trader/store';
import { dateFormats, getNetPl } from '@trader/utils';
import { useI18next } from '@trader/services';

import { BackTestHeader } from '../header';

import * as Styled from './styled';

export const ListOfMultipliers: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const multipliers = backTesting.getListOfMultipliers();

  const cells: Array<IHeadCell> = [
    {
      id: 'multiplier',
      label: translate('MULI_BANDS.MULTIPLIER'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'bandId',
      label: translate('MULI_BANDS.BAND'),
      minWidth: 50,
      align: 'center',
    },
    {
      id: 'totalProfit',
      label: translate('MULI_BANDS.TOTAL_PROFIT'),
      minWidth: 100,
      align: 'center',
      sortable: true,
    },
    {
      id: 'profitDays',
      label: translate('MULI_BANDS.DAYS_PROFIT'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'lossDays',
      label: translate('MULI_BANDS.DAYS_LOSS'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'daysWoHit',
      label: translate('MULI_BANDS.NO_HIT_DAYS'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'returnedToVwapDays',
      label: translate('MULI_BANDS.RETURN_TO_VWAP_DAYS'),
      minWidth: 130,
      align: 'center',
    },
    {
      id: 'notReturnedToVwapDays',
      label: translate('MULI_BANDS.NOT_RETURN_TO_VWAP_DAYS'),
      minWidth: 130,
      align: 'center',
    },
    {
      id: 'returnedToVwapWithLoss',
      label: translate('MULI_BANDS.RETURN_TO_VWAP_LOSS'),
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'returnedToLowerBandWithLoss',
      label: translate('MULI_BANDS.RETURN_TO_LOWER_BAND_LOSS'),
      minWidth: 210,
      align: 'center',
    },
    {
      id: 'maxConsecutiveWinDays',
      label: translate('MULI_BANDS.MAX_CONS_WIN_DAYS'),
      minWidth: 175,
      align: 'center',
    },
    {
      id: 'maxConsecutiveLossDays',
      label: translate('MULI_BANDS.MAX_CONS_LOSS_DAYS'),
      minWidth: 175,
      align: 'center',
    },
    {
      id: 'maxConsecutiveSlDays',
      label: translate('MULI_BANDS.MAX_CONS_SL_DAYS'),
      minWidth: 165,
      align: 'center',
    },
    {
      id: 'maxProfit',
      label: translate('MULI_BANDS.MAX_PROFIT'),
      minWidth: 80,
      align: 'center',
    },
    {
      id: 'positions',
      label: translate('MULI_BANDS.LIST_OF_TRADES'),
      minWidth: 100,
      align: 'center',
    },
  ];

  const renderTotalProfit = (value: number) => {
    const pl = getNetPl(value, '');
    return (
      <Styled.TotalPl $color={pl.textColor}>{pl.stringValue}</Styled.TotalPl>
    );
  };

  const renderRowItemsCb: IRenderRowItemsCallBacks<IMultiplierData> = useMemo(
    () => ({
      multiplier: item => item.value,
      bandId: item => EStripName[item.value as EStripName],
      totalProfit: item => renderTotalProfit(item.value as number),
      profitDays: item => item.value,
      lossDays: item => item.value,
      daysWoHit: item => item.value,
      returnedToVwapDays: item => item.value,
      notReturnedToVwapDays: item => item.value,
      returnedToVwapWithLoss: item => item.value,
      returnedToLowerBandWithLoss: item => item.value,
      maxConsecutiveWinDays: item => item.value,
      maxConsecutiveLossDays: item => item.value,
      maxConsecutiveSlDays: item => item.value,
      maxProfit: item => item.value,
      positions: item =>
        (item.value as unknown as Array<IBackTestingPosition>).length,
    }),
    []
  );

  const getCsvData = () => {
    return {
      data: {
        headers: cells.map(cell => ({
          label: cell.label as string,
          id: cell.id,
        })),
        rows: backTesting.getAllMultipliers().map(m => ({
          ...m,
          bandId: EStripName[m.bandId],
          positions: m.positions.length,
        })),
      },
      fileName: `${translate('MULI_BANDS.LIST_OF_MULTIPLIERS')} ${dateFormats(
        new Date(),
        'dd/mm/yyyy hh:mm:ss'
      )} `,
    };
  };

  return (
    <React.Fragment>
      <BackTestHeader onGetCsvData={getCsvData} />
      <ScrollingList<IMultiplierData>
        rows={multipliers}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
      />
    </React.Fragment>
  );
});
