import React from 'react';

import { IChartingLibraryWidget } from '../../charting_library';

import { useDisplayPendingOrder } from './useDisplayPendingOrder';
import { useDisplayAllPositions } from './useDisplayAllPositions';
import { useDisplayNewPosition } from './useDisplayNewPosition';
import { useDisplayAllOrders } from './useDisplayAllOrders';

export const useDisplayTradingMetricsLinesOnChart = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  useDisplayAllPositions(widget);
  useDisplayAllOrders(widget);
  useDisplayPendingOrder(widget);
  useDisplayNewPosition(widget);
};
