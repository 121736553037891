import { round } from 'lodash';
import { Decimal } from 'decimal.js';

export const defaultPipSize = 0.00001;

export const getPipSizeNumber = (pipSize: number | string) => {
  const pipSizeNumber = Number(pipSize);

  if (pipSizeNumber === 1) {
    return Math.log10(1 / Number(pipSize));
  }

  if (pipSizeNumber % 1 === 0) {
    return pipSizeNumber;
  }

  return Math.log10(1 / Number(pipSize));
};

export const formatByPipSize = (
  value: number | string,
  pipSize: number | string = defaultPipSize
): string => {
  const maxDecimals = getPipSizeNumber(pipSize);
  // regarding business logic all prices that formatted by pipSize mustn't be rounded, only truncated
  return new Decimal(value).toFixed(maxDecimals, Decimal.ROUND_DOWN);
};

export const handleIndicesPipSize = (
  pipSize: number,
  category: string | undefined,
  minOrderSizeIncrement: number
): number => {
  if (category === 'Indices') {
    const increment = minOrderSizeIncrement || 1;
    return round(
      increment > 1 ? +pipSize * increment : +pipSize / increment,
      getPipSizeNumber(pipSize)
    );
  }
  return pipSize;
};

export const getAmountDigitsAfterDot = (value: number): number => {
  if (!value) {
    return 0;
  }
  if (value.toString().includes('.')) {
    const maxDigit = value.toString().split('.');
    const valueMax = maxDigit[maxDigit.length - 1];
    return valueMax.length;
  }
  return 0;
};

export const checkOnTickSize = (value: number | string, tickSize: number) => {
  if (!tickSize) {
    return false;
  }

  const decimal = new Decimal(value);

  if (!decimal.mod(tickSize).equals(0)) {
    return true;
  }
};
