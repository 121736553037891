import { useEffect, useState } from 'react';

import { EApiType, EBrand } from '@trader/types';
import { Select } from '@trader/components';
import { appConfigUtils } from '@trader/utils';
import { localStorageService } from '@trader/services';
import { isChallengeAccountType, LOCAL_STORAGE_KEYS } from '@trader/constants';

import * as Styled from './styled';

// The component is build only for internal uses to simplify testing.
export const DevModeSelection = () => {
  const [selectedBrand, setSelectedBrand] = useState<EBrand>(
    appConfigUtils.getCurrentBrand()
  );
  const [selectedApiType, setSelectedApiType] = useState<EApiType>(
    appConfigUtils.getCurrentApiType()
  );

  const applicationType = isChallengeAccountType
    ? 'Challenge'
    : 'No Challenges';

  const brandOptions = Object.values(EBrand).map(brand => ({
    title: brand,
    value: brand,
  }));

  const apiTypeOptions = [
    {
      title: 'Development',
      value: EApiType.DEVELOP,
    },
    {
      title: 'Staging',
      value: EApiType.STAGING,
    },
    {
      title: 'Production',
      value: EApiType.PRODUCTION,
    },
  ];

  useEffect(() => {
    if (!localStorageService.get(LOCAL_STORAGE_KEYS.brand)) {
      localStorageService.set(
        LOCAL_STORAGE_KEYS.brand,
        appConfigUtils.getCurrentBrand()
      );
    }
    if (!localStorageService.get(LOCAL_STORAGE_KEYS.apiType)) {
      localStorageService.set(
        LOCAL_STORAGE_KEYS.apiType,
        appConfigUtils.getCurrentApiType()
      );
    }
  }, []);

  const handleBrandChange = (value: EBrand) => {
    localStorageService.set(LOCAL_STORAGE_KEYS.brand, value);
    setSelectedBrand(value);
  };

  const handleApiTypeChange = (value: EApiType) => {
    localStorageService.set(LOCAL_STORAGE_KEYS.apiType, value);
    setSelectedApiType(value);
  };

  return (
    <Styled.Root>
      <Select
        options={brandOptions}
        value={brandOptions.filter(o => o.value === selectedBrand)}
        onChange={v => handleBrandChange(v.target.value[0].value as EBrand)}
        label='Brand'
        sx={{ marginBottom: '12px' }}
      />
      <Select
        options={apiTypeOptions}
        value={apiTypeOptions.filter(o => o.value === selectedApiType)}
        onChange={v => handleApiTypeChange(v.target.value[0].value as EApiType)}
        label='API Type'
      />
      <Styled.Label>
        Selection is available only in DEV mode. <br />
        <span style={{ color: 'red' }}>{applicationType} Application</span> is
        deployed. UI mismatches may occur if an unsupported brand is selected.
      </Styled.Label>
    </Styled.Root>
  );
};
