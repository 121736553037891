import { localStorageService } from '@trader/services';
import { isMuliBandsEnv, LOCAL_STORAGE_KEYS } from '@trader/constants';
import { useMst } from '@trader/store';

/**
 * Checks the current application version against the stored version in local storage.
 * If the versions differ and the user is authenticated, logs the user out, clears persisted data,
 * and reloads the application to ensure the latest version is used.
 */
export const useAppVersion = () => {
  const store = useMst();
  const currentAppVersion = import.meta.env.VITE_APP_VERSION;
  const storageAppVersion = localStorageService.get(LOCAL_STORAGE_KEYS.version);

  if (Number(storageAppVersion) !== Number(currentAppVersion)) {
    store.auth.logOut();
    localStorageService.remove(LOCAL_STORAGE_KEYS.persist);
    localStorageService.remove(LOCAL_STORAGE_KEYS.tradingAccounts);
    store.clearRootStore();
    console.log('isMuliBandsEnv', isMuliBandsEnv);
    isMuliBandsEnv && localStorageService.clear();
  }
};
