import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';
import { calcPositionPl } from '@trader/utils';

import * as Styled from './styled';

interface ILoss extends ICommonOrderType {
  openPrice?: string;
}

export const Loss: React.FC<ILoss> = observer(({ type, openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { formState, getValues } = useFormContext<IInitialTradingFormValues>();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const shouldRecalculateLoss =
    formState.dirtyFields.stopLoss || formState.dirtyFields.stopLossPips;

  const calculateLoss = () => {
    const loss = calcPositionPl({
      side: getValues().side,
      currentPrice: +getValues().stopLoss,
      openPrice: +(openPrice || getValues().price),
      quantity: +getValues().amount,
      pipSize: instrument.pipSize,
      tickValue: instrument.tickValue,
      tickSize: instrument.tickSize,
      contractSize: instrument.contractSize,
      calcMode: instrument.calcMode,
      exchangeRate: +(trading.currencyConvert || 1),
    });
    trading.updateLoss(loss);
  };

  useEffect(() => {
    if (!shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [shouldRecalculateLoss, trading.currencyConvert, getValues().amount]);

  useEffect(() => {
    if (shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [
    shouldRecalculateLoss,
    instrument.ask,
    instrument.bid,
    getValues().amount,
  ]);

  useEffect(() => {
    calculateLoss();
  }, [getValues().side, getValues().orderType]);

  if (formState.errors.stopLoss || formState.errors.stopLossPips) {
    return (
      <Styled.InputLabel>
        {translate('COMMON.LABELS.LOSS')}: --
      </Styled.InputLabel>
    );
  }

  return (
    <Styled.InputLabel>
      {translate('COMMON.LABELS.LOSS')}: {trading.loss}
    </Styled.InputLabel>
  );
});
