import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from '@trader/utils';
import { positionPlCalcMode } from '@trader/constants';

// The optional params needs here to match two interfaces.
// We need to refactor to keep the same naming convention.
// TODO: (av) Refactor the naming to remove the uneeded optional params here.
type TPosition = {
  side: TPlaceOrderSide;
  price?: number;
  currentPrice?: number;
  openPrice: number;
  quantity: number;
  pipSize: number;
  tickValue: number;
  tickSize: number;
  contractSize: number;
  calcMode: number;
  exchangeRate?: number;
  conversionRate?: number;
};

const calcCfdPositionPl = (
  side: TPlaceOrderSide,
  currentPrice: number,
  openPrice: number,
  quantity: number,
  pipSize: number
) => {
  const normalize = (value: number) => +formatByPipSize(value, pipSize);

  return side === 'Buy'
    ? normalize((currentPrice - openPrice) * quantity)
    : normalize((openPrice - currentPrice) * quantity);
};

const calcForexPositionPl = (
  side: TPlaceOrderSide,
  currentPrice: number,
  openPrice: number,
  quantity: number,
  pipSize: number
) => {
  const normalize = (value: number) => +formatByPipSize(value, pipSize);

  return side === 'Buy'
    ? normalize(currentPrice * quantity) - normalize(openPrice * quantity)
    : normalize(openPrice * quantity) - normalize(currentPrice * quantity);
};

const calcFuturesPositionPl = (
  side: TPlaceOrderSide,
  currentPrice: number,
  openPrice: number,
  quantity: number,
  pipSize: number,
  tickValue: number,
  tickSize: number,
  contractSize: number
) => {
  const normalize = (value: number) => +formatByPipSize(value, pipSize);
  const volumeInLots = quantity / contractSize;

  return side === 'Buy'
    ? normalize(
        ((currentPrice - openPrice) * volumeInLots * tickValue) / tickSize
      )
    : normalize(
        ((openPrice - currentPrice) * volumeInLots * tickValue) / tickSize
      );
};

export const calcPositionPl = (position: TPosition) => {
  const normalize = (value: number) =>
    +formatByPipSize(value, position.pipSize);

  let value = 0;

  switch (position.calcMode) {
    // Forex and Forex without leverage
    case positionPlCalcMode.TRADE_MODE_FOREX:
    case positionPlCalcMode.TRADE_MODE_FOREX_NO_LEVERAGE:
      value = calcForexPositionPl(
        position.side,
        position.price || position.currentPrice || 0,
        position.openPrice,
        position.quantity,
        position.pipSize
      );
      break;

    // Futures and related modes
    case positionPlCalcMode.TRADE_MODE_FUTURES:
    case positionPlCalcMode.TRADE_MODE_EXCH_FUTURES:
    case positionPlCalcMode.TRADE_MODE_EXCH_FORTS:
    case positionPlCalcMode.TRADE_MODE_EXCH_OPTIONS:
    case positionPlCalcMode.TRADE_MODE_EXCH_OPTIONS_MARGIN:
      value = calcFuturesPositionPl(
        position.side,
        position.price || position.currentPrice || 0,
        position.openPrice,
        position.quantity,
        position.pipSize,
        position.tickValue,
        position.tickSize,
        position.contractSize
      );
      break;

    // CFD and related modes
    case positionPlCalcMode.TRADE_MODE_CFD:
    case positionPlCalcMode.TRADE_MODE_CFDINDEX:
    case positionPlCalcMode.TRADE_MODE_CFDLEVERAGE:
    case positionPlCalcMode.TRADE_MODE_EXCH_STOCKS:
    case positionPlCalcMode.TRADE_MODE_EXCH_OPTIONS_STOCKS_MOEX:
      value = calcCfdPositionPl(
        position.side,
        position.price || position.currentPrice || 0,
        position.openPrice,
        position.quantity,
        position.pipSize
      );
      break;

    // Bonds and related modes
    case positionPlCalcMode.TRADE_MODE_EXCH_OPTIONS_BONDS:
    case positionPlCalcMode.TRADE_MODE_EXCH_OPTIONS_BONDS_MOEX:
      value = calcCfdPositionPl(
        position.side,
        position.price || position.currentPrice || 0,
        position.openPrice,
        position.quantity,
        position.pipSize
      );
      break;

    default:
      value = calcCfdPositionPl(
        position.side,
        position.price || position.currentPrice || 0,
        position.openPrice,
        position.quantity,
        position.pipSize
      );
      break;
  }

  const exchangeRate = position.exchangeRate || position.conversionRate || 0;
  return normalize(value * exchangeRate);
};

export const calcAllPositionsPl = (
  positions: (TPosition & { swap: number })[],
  options?: { withoutSwap?: boolean }
) => {
  if (!positions.length) {
    return 0;
  }

  return positions.reduce((acc, curr) => {
    const value = (options?.withoutSwap ? 0 : curr.swap) + calcPositionPl(curr);
    return acc + value;
  }, 0);
};
