import React from 'react';
import { observer } from 'mobx-react-lite';

import { ModalNotification } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

export const MuliBandsDisabled: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  return (
    <ModalNotification
      iconTypeName='warning'
      title={''}
      content={translate('MULI_BANDS.STRATEGY_DISABLED')}
      buttonContent={translate('COMMON.LABELS.PROCEED')}
      onClick={() => {
        window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        store.ui.modal.close();
      }}
    />
  );
});
