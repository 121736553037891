export const defaultPipSize = 0.0001;
export const defaultPipsWithTickSize = '1';
export const maxOrderPipsValue = 999999999;
export const minOrderPipsValue = 0;

export const tradingCodes = {
  success: ['0', '10001', '10002', '10003', '10008', '10009', '100010'],
  warning: ['10004', '10005'],
  error: [
    '10006',
    '10007',
    '10011',
    '10012',
    '10013',
    '10014',
    '10015',
    '10016',
    '10017',
    '10018',
    '10019',
    '10020',
    '10021',
    '10022',
    '10023',
    '10024',
    '10025',
    '10026',
    '10027',
    '10028',
    '10029',
    '10030',
    '10031',
    '10032',
    '10033',
    '10034',
    '10035',
    '10036',
    '10037',
    '10038',
    '10039',
    '10040',
    '10041',
    '10042',
    '10043',
    '10044',
    '10045',
    '10046',
  ],
};
export const tradingCodeMessages = {
  error: {
    10006: 'WARNINGS.TRADING_REQUEST_REJECTED',
    10007: 'WARNINGS.TRADING_REQUEST_CANCELED',
    10011: 'WARNINGS.TRADING_ACTION_FAILED',
    10012: 'WARNINGS.TRADING_REQUEST_TIMEOUT',
    10013: 'WARNINGS.TRADING_REQUEST_INVALID',
    10014: 'WARNINGS.TRADING_INVALID_VOLUME',
    10015: 'WARNINGS.TRADING_INVALID_PRICE',
    10016: 'WARNINGS.TRADING_WRONG_STOPS_LEVELS_OR_PRICE',
    10017: 'WARNINGS.TRADING_IS_DISABLED',
    10018: 'WARNINGS.TRADING_MARKET_CLOSED',
    10019: 'WARNINGS.TRADING_NOT_ENOUGH_MONEY',
    10020: 'WARNINGS.TRADING_PRICE_HAS_CHANGED',
    10021: 'WARNINGS.TRADING_NO_PRICE',
    10022: 'WARNINGS.TRADING_INVALID_ORDER_EXPIRATION',
    10023: 'WARNINGS.TRADING_ORDER_HAS_BEEN_CHANGED',
    10024: 'WARNINGS.TRADING_TOO_MANY_TRADE_REQUESTS',
    10025: 'WARNINGS.TRADING_NO_CHANGES_IN_REQUEST',
    10026: 'WARNINGS.TRADING_AUTOTRADING_DISABLED_SERVER',
    10027: 'WARNINGS.TRADING_AUTOTRADING_DISABLED_CLIENT',
    10028: 'WARNINGS.TRADING_REQUEST_BLOCKED_BY_DEALER',
    10029: 'WARNINGS.TRADING_MODIFICATION_FAILED_CLOSE_TO_MARKET',
    10030: 'WARNINGS.TRADING_FILL_MODE_NOT_SUPPORTED',
    10031: 'WARNINGS.TRADING_NO_CONNECTION',
    10032: 'WARNINGS.TRADING_REAL_ACCOUNTS_ONLY',
    10033: 'WARNINGS.TRADING_ORDER_LIMIT_REACHED',
    10034: 'WARNINGS.TRADING_VOLUME_LIMIT_REACHED',
    10035: 'WARNINGS.TRADING_INVALID_ORDER_TYPE',
    10036: 'WARNINGS.TRADING_POSITION_ALREADY_CLOSED',
    10037: 'WARNINGS.TRADING_ACTION_FAILED',
    10038: 'WARNINGS.TRADING_VOLUME_EXCEEDS_CURRENT_POSITION',
    10039: 'WARNINGS.TRADING_ORDER_TO_CLOSE_POSITION_EXISTS',
    10040: 'WARNINGS.TRADING_ACTION_FAILED',
    10041: 'WARNINGS.TRADING_REQUEST_REJECTED',
    10042: 'WARNINGS.TRADING_LONG_POSITIONS_ONLY',
    10043: 'WARNINGS.TRADING_SHORT_POSITIONS_ONLY',
    10044: 'WARNINGS.TRADING_POSITION_CLOSING_ONLY',
    10045: 'WARNINGS.TRADING_POSITION_CLOSURE_FIFO_RULE',
    10046: 'WARNINGS.TRADING_HEDGE_POSITIONS_PROHIBITED',
  },
} as const;
export const positionPlCalcMode = {
  TRADE_MODE_FOREX: 0,
  TRADE_MODE_FUTURES: 1,
  TRADE_MODE_CFD: 2,
  TRADE_MODE_CFDINDEX: 3,
  TRADE_MODE_CFDLEVERAGE: 4,
  TRADE_MODE_FOREX_NO_LEVERAGE: 5,
  TRADE_MODE_EXCH_STOCKS: 32,
  TRADE_MODE_EXCH_FUTURES: 33,
  TRADE_MODE_EXCH_FORTS: 34,
  TRADE_MODE_EXCH_OPTIONS: 35,
  TRADE_MODE_EXCH_OPTIONS_MARGIN: 36,
  TRADE_MODE_EXCH_OPTIONS_BONDS: 37,
  TRADE_MODE_EXCH_OPTIONS_STOCKS_MOEX: 38,
  TRADE_MODE_EXCH_OPTIONS_BONDS_MOEX: 39,
  TRADE_MODE_SERV_COLLATERAL: 64,
};
