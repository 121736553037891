import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { Icon, Typography, Wrapper } from '@trader/components';

import { TTradingAccountEntity, useMst } from '@trader/store';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';
import { useI18next } from '@trader/services';
import { EChallenge, EChallengeStatus } from '@trader/types';
import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  formatAccountCurrencyValue,
  formatAccountPercentageValue,
} from '@trader/utils';
import {
  EConditionType,
  useBusinessLogic,
  useTradingAccountPositions,
} from '@trader/hooks';

import * as Styled from './styled';

export const AccountInformation: React.FC = observer(() => {
  const store = useMst();
  const { isScoreLevelLow } = useBusinessLogic(EConditionType.ScoreLevel);

  const allAccounts =
    store.entities.tradingAccounts.getAll<TTradingAccountEntity>();
  const accounts = isScoreLevelLow
    ? allAccounts.filter(acc => acc.accountType === EAccountTypeNames.Demo)
    : allAccounts;

  useEffect(() => {
    store.entities.tradingAccounts.getTradingAccountsAsync.run();
    if (isChallengeAccountType) {
      store.entities.challenges.getChallengesAsync.run();
    }
  }, []);

  return (
    <Styled.Root>
      <Stack direction='column' spacing='46px' padding='6px 18px 18px 18px'>
        {accounts.map(account => (
          <Account key={account.platformLogin} account={account} />
        ))}
      </Stack>
    </Styled.Root>
  );
});

const Account: React.FC<{
  account: TTradingAccountEntity;
}> = observer(({ account }) => {
  const { translate } = useI18next();

  const store = useMst();
  const challenge = store.user.getAccountChallenge(account.tradingId);

  const { positionsPl } = useTradingAccountPositions(account.tradingId);

  const portfolio = account.portfolio;
  const currencySymbol = account.currencySymbol;
  const isLiveAccount = account.accountType === EAccountTypeNames.Live;
  const equity = !portfolio
    ? null
    : calcEquity(positionsPl, portfolio.balance, portfolio.credit);

  const handleChangeAccount = () => {
    store.entities.tradingAccounts.changeTradingAccountAsync.run({
      tradingId: account.tradingId,
    });
  };

  const handleOpenAddFundsModal = () => {
    store.ui.modal.open('addFunds', {
      tradingAccountId: account.tradingId,
      tradingAccountSymbol: currencySymbol,
    });
  };

  const handleOpenAccountStatementModal = () => {
    store.ui.modal.open('accountStatement', {
      platformLogin: account.platformLogin,
      tradingAccountCurrency: account.currency,
      product: account.product,
      accountType: account.accountType,
    });
  };

  return (
    <Styled.Account>
      <Stack
        direction='row'
        alignItems='center'
        spacing='8px'
        marginBottom='10px'
      >
        <Icon iconType='account' />
        <Typography variant='medium' fontWeight='medium'>
          {isChallengeAccountType ? account.tradingId : account.platformLogin}
        </Typography>
        {!isChallengeAccountType && (
          <>
            <Styled.Badge>{account.product}</Styled.Badge>
            <Styled.Badge>
              {translate(`COMMON.LABELS.${account.accountType.toUpperCase()}`)}
            </Styled.Badge>
            <Styled.Badge>{account.currency}</Styled.Badge>
          </>
        )}
        {account.isInUse ? (
          <Styled.Badge $isActive>
            {translate('COMMON.LABELS.ACTIVE')}
          </Styled.Badge>
        ) : (
          <Styled.SwitchAccountButton
            disabled={
              account.isInUse ||
              challenge?.status === EChallengeStatus.Disable ||
              challenge?.hierarchyType === EChallenge.Slave
            }
            onClick={handleChangeAccount}
          >
            <Wrapper alignItems='center'>
              <Typography>{translate('COMMON.LABELS.SWITCH')}</Typography>
              <Icon iconType='switchAccount' />
            </Wrapper>
          </Styled.SwitchAccountButton>
        )}
      </Stack>
      <Styled.Info>
        <Styled.Portfolio>
          <Styled.PortfolioItem width='80px'>
            <Typography>{translate('COMMON.LABELS.NET_PL')}</Typography>
            <Typography>
              {formatAccountCurrencyValue(positionsPl, currencySymbol)}
            </Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='90px'>
            <Typography>{translate('COMMON.LABELS.EQUITY')}</Typography>
            <Typography>
              {formatAccountCurrencyValue(equity, currencySymbol)}
            </Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='100px'>
            <Typography>{translate('COMMON.LABELS.FREE_MARGIN')}</Typography>
            <Typography>
              {formatAccountCurrencyValue(
                equity && portfolio
                  ? calcFreeMargin(equity, portfolio.usedMargin)
                  : null,
                currencySymbol
              )}
            </Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='100px'>
            <Typography>{translate('COMMON.LABELS.USED_MARGIN')}</Typography>
            <Typography>
              {formatAccountCurrencyValue(
                portfolio ? portfolio.usedMargin : null,
                currencySymbol
              )}
            </Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='100px'>
            <Typography>{translate('COMMON.LABELS.MARGIN_LEVEL')}</Typography>
            <Typography>
              {formatAccountPercentageValue(
                equity && portfolio
                  ? calcMarginLevel(equity, portfolio.usedMargin)
                  : null
              )}
            </Typography>
          </Styled.PortfolioItem>
          {isLiveAccount && (
            <Styled.PortfolioItem width='165px'>
              <Typography>
                {translate('COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL')}
              </Typography>
              <Typography>
                {formatAccountCurrencyValue(
                  equity && portfolio
                    ? calcFreeMargin(equity, portfolio.usedMargin) < 0
                      ? null
                      : calcFreeMargin(equity, portfolio.usedMargin)
                    : null,
                  currencySymbol
                )}
              </Typography>
            </Styled.PortfolioItem>
          )}
        </Styled.Portfolio>
        <Stack direction='row' spacing='10px'>
          <Styled.Button
            disabled={account.accountType === EAccountTypeNames.Demo}
            onClick={handleOpenAddFundsModal}
          >
            {translate('ADD_FUNDS.TITLE')}
          </Styled.Button>
          {!isChallengeAccountType && (
            <Styled.OutlinedButton onClick={handleOpenAccountStatementModal}>
              {translate('COMMON.LABELS.ACCOUNT_STATEMENT')}
            </Styled.OutlinedButton>
          )}
        </Stack>
      </Styled.Info>
    </Styled.Account>
  );
});
