import styled, { css } from 'styled-components';

import { Button, Wrapper } from '@trader/components';
import { muliBandsPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 41px;
    padding: 0 ${muliBandsPadding}px;
    border-bottom: 1px solid ${theme.palette.background.default};
    justify-content: space-between;
    align-items: center;
  `};
`;

export const Download = styled(Button)`
  ${({ theme }) => css`
    width: 112px;
    height: 25px;
    border-radius: 5px;

    color: ${theme.palette.common.white};

    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};

    &:hover {
      background: ${theme.palette.primary.main};
    }
  `};
`;
