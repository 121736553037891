import styled, { css } from 'styled-components';
import { Icon, IconButton, Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';
import { conditionApply } from '@trader/utils';

interface IHide {
  $isHide: boolean;
}

export const Root = styled(Wrapper)<IHide>`
  ${({ theme, $isHide }) => css`
    flex-direction: column;
    position: relative;
    width: 100%;
    background: ${theme.palette.background.paper};
    background: ${theme.palette.background.paper};
    padding-top: 7px;
    margin-top: ${layoutMargin}px;
    height: 300px;
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
    transition: height 0.1s ease;

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }

    .MuiTabs-root {
      padding-bottom: 0;
      padding-left: 14px;
      min-height: 40px;
      border-bottom: none;
    }

    .scroll-list-header {
      height: 32px;

      th {
        border-bottom: 1px solid ${theme.palette.background.default}!important;
        background-color: ${theme.palette.background.paper}!important;

        > span {
          cursor: pointer;
          color: ${theme.palette.red.light};
          font-size: ${theme.typography.tiny.fontSize};
          font-weight: ${theme.typography.fontWeightMedium};
        }
      }
    }

    @media (max-height: 1080px) {
      height: 175px;
    }

    ${conditionApply(
      [$isHide],
      css`
        padding-top: 0;
        height: 43px;
        transition: height 0.1s ease;
      `
    )}

    ${theme.breakpoints.down('sm')} {
      .MuiTabs-root {
        padding: 10px 5px;
      }
    }
  `};
`;

export const Toggle = styled(IconButton)<IHide>`
  ${({ theme, $isHide }) => css`
    position: absolute;
    top: ${$isHide ? '5px' : '14px'};
    right: ${$isHide ? '5px' : '14px'};
    z-index: ${theme.zIndex.modal};
  `};
`;

export const Title = styled('div')`
  ${({ theme }) => css`
    display: none;

    ${theme.breakpoints.down('sm')} {
      display: block;
      text-transform: uppercase;
      padding: 0 0 5px 10px;
      font-size: ${theme.typography.default.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
      line-height: 17px;
    }
  `};
`;

export const WarningIcon = styled(Icon)`
  ${() => css`
    width: 18px;
    height: 18px;
    margin: 2px 0 0 4px;
  `};
`;
