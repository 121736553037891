import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/material';

import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import {
  IconButton,
  Popover,
  SearchInstrument,
  Wrapper,
} from '@trader/components';

import * as Styled from '../../styled';
import { MarketStatusIcon } from '../marketStatusIcon';
import { FavouriteIconButton } from '../favoriteIconButton';
import { PriceAlertIconButton } from '../priceAlertIconButton';

interface IRightSideActionBar {
  instrument: TInstrumentEntity;
  isMinimized?: boolean;
  isMuliBands?: boolean;
  searchCategory?: string;
  onChangeSymbol?: (symbol: string) => void;
}

export const RightSideActionBar: React.FC<IRightSideActionBar> = observer(
  ({
    instrument,
    isMinimized,
    isMuliBands,
    searchCategory,
    onChangeSymbol,
  }) => {
    const { translate } = useI18next();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const store = useMst();
    const shouldShowTradingCentral = store.user.settings.isDisplayedByUser;
    const isKeyStatisticsSelected =
      store.ui.sidebar.isRightBarKeyStatisticsOpened() &&
      store.ui.sidebar.rightBar.infoSection.symbol === instrument.symbol;
    const isTradingCentralSelected =
      store.ui.sidebar.isRightBarTradingCentralOpened() &&
      store.ui.sidebar.rightBar.infoSection.symbol === instrument.symbol;

    const handleInfoSectionOpen = (
      contentType: 'keyStatistics' | 'tradingCentral'
    ) => {
      store.ui.sidebar.openRightBarInfoSection(contentType, instrument.symbol);
      isMinimized && setIsMenuOpen(false);
    };

    const renderRightSideItems = () => (
      <>
        {!isMuliBands && (
          <MarketStatusIcon
            isUnavailable={instrument.tradingAvailability.isUnavailable}
            isHoliday={instrument.tradingAvailability.isHoliday}
            openIn={instrument.tradingAvailability.openIn}
          />
        )}
        {isMuliBands && (
          <Styled.InstrumentSearch>
            <SearchInstrument
              placeholder={translate('COMMON.LABELS.SEARCH_INSTRUMENT')}
              onChangeSymbol={onChangeSymbol}
              searchCategory={searchCategory}
            />
          </Styled.InstrumentSearch>
        )}
        <FavouriteIconButton instrument={instrument} />
        <PriceAlertIconButton symbol={instrument.symbol} />
        {!isMuliBands && (
          <Styled.InfoButton
            $isSelected={isKeyStatisticsSelected}
            onClick={() => handleInfoSectionOpen('keyStatistics')}
          >
            {translate('COMMON.LABELS.KEY_STATISTICS')}
          </Styled.InfoButton>
        )}
        {!isMuliBands && shouldShowTradingCentral && (
          <Styled.InfoButton
            $isSelected={isTradingCentralSelected}
            onClick={() => handleInfoSectionOpen('tradingCentral')}
            $withLeftSpacing
          >
            {translate('COMMON.LABELS.TRADING_CENTRAL')}
          </Styled.InfoButton>
        )}
      </>
    );

    if (isMinimized) {
      return (
        <Wrapper
          flexDirection='column'
          alignItems='flex-end'
          justifyContent='flex-end'
        >
          {!isMuliBands && (
            <Popover
              behavior='click'
              elevation={4}
              showContent={isMenuOpen}
              trigger={
                <IconButton
                  iconType='menu'
                  style={{ paddingRight: '4px' }}
                  onClick={() => setIsMenuOpen(true)}
                />
              }
              content={
                <Wrapper flexDirection='column' width='150px' padding='6px'>
                  <Styled.MenuItem
                    $isSelected={isKeyStatisticsSelected}
                    onClick={() => handleInfoSectionOpen('keyStatistics')}
                  >
                    {translate('COMMON.LABELS.KEY_STATISTICS')}
                  </Styled.MenuItem>
                  {shouldShowTradingCentral && (
                    <Styled.MenuItem
                      $isSelected={isTradingCentralSelected}
                      onClick={() => handleInfoSectionOpen('tradingCentral')}
                    >
                      {translate('COMMON.LABELS.TRADING_CENTRAL')}
                    </Styled.MenuItem>
                  )}
                </Wrapper>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          )}
          <Stack direction='row' spacing='12px'>
            {renderRightSideItems()}
          </Stack>
        </Wrapper>
      );
    }

    return (
      <Stack direction='row' spacing='17px' alignItems='center'>
        {renderRightSideItems()}
      </Stack>
    );
  }
);
