import styled, { css } from 'styled-components';
import { Wrapper, Typography, Button } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 408px;
    height: 366px;
    padding: 35px 25px 0 25px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Content = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 25px;

    > p:nth-child(1) {
      font-size: ${theme.typography.heading.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }

    > p:nth-child(2) {
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      margin-top: 8px;
    }

    > p:nth-child(3) {
      font-size: ${theme.typography.heading.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      text-transform: uppercase;
      margin-top: 20px;
    }

    > p:nth-child(4) {
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      margin-top: 25px;
    }
  `};
`;

export const Footer = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: space-between;

    > button:first-child {
      margin-right: 10px;
    }
  `};
`;

interface IButton {
  $isSignOut: boolean;
}

export const ActionButton = styled(Button)<IButton>`
  ${({ theme, $isSignOut }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56%;
    height: 50px;
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.white.main};
    background: ${theme.palette.primary.main};

    ${conditionApply(
      [$isSignOut],
      css`
        background: ${theme.palette.background.paper};
        color: ${theme.palette.primary.dark};
        width: 44%;
        border: 1px solid ${theme.palette.primary.dark};
      `
    )}
    &:hover {
      background: ${$isSignOut
        ? theme.palette.background.paper
        : theme.palette.primary.main};
    }
  `};
`;
