import React, { ReactNode, UIEvent, useState } from 'react';
import { TableContainer, TableHead } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { objectUtils } from '@trader/utils';
import { Wrapper } from '@trader/components';

import { sortRows } from './utils';
import * as Styled from './styled';

export {
  SymbolWithIcon,
  PurchaseType,
  ChangePercentage,
  ProfitValue,
} from './components';

export interface IHeadCell {
  id: string;
  label: string | ReactNode;
  padding?: string;
  minWidth?: number;
  sortable?: boolean;
  sortType?: TSortType;
  align?: 'center' | 'start' | 'end';
}

export type TRowItemValue<T = string | number | null | boolean> = T;

interface IRenderRowItemsCallBacksRenderItem<T> {
  value: TRowItemValue;
  id: IHeadCell['id'];
  row: T;
  rowIndex: number;
  renderNode?: (value: TRowItemValue) => React.ReactNode;
}

type TRenderRowItemsCallBacksKeyValue<T> = (
  item: IRenderRowItemsCallBacksRenderItem<T>
) => React.ReactNode | TRowItemValue;

export type TSortOrder = 'asc' | 'desc';
export type TSortType = 'number' | 'date';
type TSortState = {
  sortType: TSortType;
  sortOrder: TSortOrder;
  sortByCellId?: string;
};

export interface IRenderRowItemsCallBacks<T> {
  [key: string]: TRenderRowItemsCallBacksKeyValue<T>;
}

interface IRenderRow<T> {
  headCells: Array<IHeadCell>;
  row: T;
  rowIndex: number;
  renderRowItemsCallBacks: IRenderRowItemsCallBacks<T>;
}

interface IScrollingList<T> {
  headCells: Array<IHeadCell>;
  rows: Array<T>;
  totalCount?: number;
  fetchMore?: (pageNumber: number) => Promise<void>;
  renderRowItemsCallBacks: IRenderRowItemsCallBacks<T>;
  renderFooter?: () => React.ReactNode;
}

export const ScrollingList = <TRow extends object>({
  headCells,
  rows,
  renderRowItemsCallBacks,
  renderFooter,
  totalCount,
  fetchMore,
}: IScrollingList<TRow>) => {
  const [sortState, setSortState] = useState<TSortState>({
    sortType: 'number',
    sortOrder: 'desc',
    sortByCellId: undefined,
  });
  const [tableState, setTableState] = useState({
    pageNumber: 1,
    isFetching: false,
  });

  const handleSortableCellClick = (cell: IHeadCell) => {
    const isClickedOnNewColumn = sortState.sortByCellId !== cell.id;
    setSortState({
      sortType: cell.sortType || 'number',
      sortOrder:
        sortState.sortOrder === 'asc' || isClickedOnNewColumn ? 'desc' : 'asc',
      sortByCellId: cell.id,
    });
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (!fetchMore || !totalCount) return;

    const { scrollTop, clientHeight, scrollHeight } =
      event.target as HTMLElement;

    const extraHeightForScrolling = 900;
    const shouldFetchMore =
      scrollTop + clientHeight >= scrollHeight - extraHeightForScrolling;

    if (shouldFetchMore && rows.length < totalCount && !tableState.isFetching) {
      handleFetch();
    }
  };

  const handleFetch = async () => {
    if (fetchMore) {
      setTableState(prev => ({
        pageNumber: prev.pageNumber + 1,
        isFetching: true,
      }));
      await fetchMore(++tableState.pageNumber);
      setTableState(prev => ({
        ...prev,
        isFetching: false,
      }));
    }
  };

  return (
    <TableContainer onScroll={handleScroll}>
      <Styled.Root stickyHeader aria-label='sticky table'>
        <TableHead>
          <Styled.Header className='scroll-list-header'>
            {headCells.map(c => (
              <Styled.CellValue
                key={c.id}
                $minWidth={c.minWidth}
                $align={c.align}
                $padding={c.padding}
              >
                {c.sortable ? (
                  <Wrapper
                    alignItems='center'
                    justifyContent='center'
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSortableCellClick(c)}
                  >
                    <span>{c.label}</span>
                    <Styled.SortIcon
                      iconType='positions'
                      $sortOrder={
                        sortState.sortByCellId === c.id
                          ? sortState.sortOrder
                          : null
                      }
                    />
                  </Wrapper>
                ) : (
                  c.label
                )}
              </Styled.CellValue>
            ))}
          </Styled.Header>
        </TableHead>

        <Styled.Body $isAddMarginToLastItem={!!renderFooter}>
          {sortRows<TRow>({ rows, ...sortState }).map((row, rowIndex) => (
            <RenderRow<TRow>
              row={row}
              key={JSON.stringify(row)}
              headCells={headCells}
              renderRowItemsCallBacks={renderRowItemsCallBacks}
              rowIndex={rowIndex}
            />
          ))}
        </Styled.Body>
      </Styled.Root>
      {renderFooter && (
        <Styled.Footer>
          <div>{renderFooter()}</div>
        </Styled.Footer>
      )}
    </TableContainer>
  );
};

const RenderRow = observer(
  <T extends object>({
    headCells,
    row,
    renderRowItemsCallBacks,
    ...rest
  }: IRenderRow<T>) => {
    return (
      <Styled.Item>
        {headCells.map(cell => {
          const value = objectUtils.getValueByPath<TRowItemValue>(row, cell.id);
          const renderCallback = renderRowItemsCallBacks[cell.id];

          return (
            <Styled.RowValue
              key={cell.id}
              $minWidth={cell.minWidth}
              $align={cell.align}
              $padding={cell.padding}
            >
              {renderCallback({
                value: value === undefined ? null : value,
                id: cell.id,
                row,
                ...rest,
              }) || value}
            </Styled.RowValue>
          );
        })}
      </Styled.Item>
    );
  }
);
