import { getRoot, IAnyStateTreeNode, Instance, types } from 'mobx-state-tree';

import {
  formatMoney,
  getAmountDigitsAfterDot,
  runInAction,
} from '@trader/utils';
import { instrumentModel } from '../entities/instruments/index';
import {
  getInstrumentReferenceAsync,
  getRequiredOpenCostAsync,
  prePlaceOrderAsync,
} from './actions';
import {
  TAddProtectionType,
  TPlaceOrderSide,
  TPlaceOrderType,
} from '@trader/types';
import { getRootInstance } from '../configureStore/configureStore';

const commonTradingModel = types
  .model('commonTradingModel', {
    instrument: types.maybeNull(
      types.safeReference(instrumentModel, {
        get(identifier, parent: IAnyStateTreeNode) {
          const root = getRoot(parent) as IAnyStateTreeNode;
          const entity = root.entities.instruments.get(identifier);
          return entity || null;
        },
        set(value) {
          return value.symbol;
        },
      })
    ),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    orderType: types.frozen<TPlaceOrderType>('Market'),
    amount: types.optional(types.string, '0'),
    price: types.optional(types.string, '0'),
    chartPrice: types.optional(types.string, '0'),
    isOrderInPending: false,

    requiredMargin: types.maybeNull(types.number),
    currencyConvert: types.maybeNull(types.number),
    isRequiredOpenCostError: false,

    stopLossType: types.frozen<TAddProtectionType>('Rate'),
    takeProfitType: types.frozen<TAddProtectionType>('Rate'),
    isStopLoss: false,
    isTakeProfit: false,
    stopLossPips: types.optional(types.string, '0'),
    stopLoss: types.optional(types.string, '0'),
    takeProfit: types.optional(types.string, '0'),
    chartTakeProfit: types.optional(types.string, '0'),
    takeProfitPips: types.optional(types.string, '0'),

    profit: types.optional(types.string, '--'),
    loss: types.optional(types.string, '--'),

    getInstrumentReferenceAsync,
    prePlaceOrderAsync,
    getRequiredOpenCostAsync,
  })
  .views(state => ({
    amountDigitsAfterDot: () =>
      state.instrument
        ? getAmountDigitsAfterDot(state.instrument.minOrderSizeIncrement)
        : 0,
  }))
  .actions(state => ({
    runInAction,
    setFormField: (name: string, value: string | number | boolean) => {
      state[name] = value;
    },
    updateProfit: (value: number) => {
      const root = getRootInstance();
      state.profit = formatMoney(value, {
        currencySymbol: root.user.tradingAccount?.currencySymbol || '',
      });
    },
    updateLoss: (value: number) => {
      const root = getRootInstance();
      state.loss = formatMoney(value, {
        currencySymbol: root.user.tradingAccount?.currencySymbol || '',
      });
    },
    updatePriceFromChart: (value: string) => {
      state.chartPrice = value;
    },
    updateTPFromChart: (value: string) => {
      state.chartTakeProfit = value;
    },
    resetOrderType: () => {
      state.side = null;
      state.orderType = 'Market';
    },
    updateSide: (side: TPlaceOrderSide) => {
      state.side = side;
    },
    updateOrderType: (type: TPlaceOrderType) => {
      state.orderType = type;
    },
    toggleProtectionType: (
      key: 'stopLossType' | 'takeProfitType',
      value: TAddProtectionType
    ) => {
      state[key] = value;
    },
    toggleIsProtection: (
      key: 'isTakeProfit' | 'isStopLoss',
      value: boolean
    ) => {
      state[key] = value;
    },
    clear: () => {
      state.instrument = null;
      state.requiredMargin = null;
      state.currencyConvert = null;
      state.side = 'Buy';
      state.orderType = 'Market';
      state.amount = '0';
      state.price = '0';
      state.isOrderInPending = false;
      state.isRequiredOpenCostError = false;
      state.stopLossType = 'Rate';
      state.takeProfitType = 'Rate';
      state.isStopLoss = false;
      state.isTakeProfit = false;
      state.stopLossPips = '0';
      state.stopLoss = '0';
      state.takeProfit = '0';
      state.takeProfitPips = '0';
      state.profit = '--';
    },
  }));

export const commonTrading = types.optional(commonTradingModel, {});

export type TCommonTradingInstance = Instance<typeof commonTradingModel>;
