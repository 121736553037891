import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import { calcPositionPl, getNetPl } from '@trader/utils';
import { adapterLineStyle, emptyFn } from '@trader/constants';
import { devLoggerService, useI18next } from '@trader/services';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';

interface IPosition {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const positionLineLength = 39;
const positions = new Map<string, IPosition>();

export const useDisplayAllPositions = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken;

  const { typeColor } = useGetAdapterDiff('Position');

  const trading = store.trading.getTrading('createOrder');

  const positionsMetrics =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const symbol = trading.instrument?.symbol as string;
  const shouldDisplayAllPositionsOnChart = !trading.side;

  const symbolPositionsMetrics = positionsMetrics.filter(
    metric => metric.symbol === symbol
  );

  const getInstrumentPl = (pl: number) => {
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    return getNetPl(pl, instrument?.currencySymbol);
  };

  const createPosition = (id: string) => {
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    const currentPl = getInstrumentPl(position.pl);

    const ref = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(currentPl.stringValue)
      .setBodyTextColor(currentPl.textColor)
      .setCloseTooltip(translate('COMMON.LABELS.CLOSE_POSITION'))
      .setCloseButtonBorderColor(typeColor(id, position.side))
      .setCloseButtonBackgroundColor(typeColor(id, position.side))
      .setBodyBorderColor(typeColor(id, position.side))
      .setBodyBackgroundColor(typeColor(id, position.side))
      .setCloseButtonIconColor(theme.palette.white.main)
      .setLineColor(typeColor(id, position.side))
      .setLineLength(positionLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity(
        `${translate(`COMMON.LABELS.${position.side?.toUpperCase()}`)}: ${
          position?.quantity
        }`
      )
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .onClose(async function () {
        try {
          const metric =
            store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

          if (metric) {
            await metric.closePositionAsync.run({
              positionId: id,
              quantity: metric?.quantity,
            });
          }
        } catch (e) {
          devLoggerService.error('onCancel called', e);
        }
      })
      .setPrice(position?.openPrice);

    positions.set(id, {
      ...positions.get(id),
      key: id,
      ref,
    });
  };

  const clearPositions = useCallback(() => {
    Array.from(positions.values()).forEach(position => {
      position?.ref?.remove();
    });
    positions.clear();
  }, []);

  // initialize positions
  useEffect(() => {
    widget.current?.onChartReady(() => {
      widget.current
        ?.activeChart()
        .onDataLoaded()
        .subscribe(null, () => {
          symbolPositionsMetrics.forEach(metric => {
            if (!metric) {
              return;
            }
            if (!positions.get(metric.positionId)) {
              createPosition(metric.positionId);
            }
          });
        });
    });
    return () => clearPositions();
  }, [symbol, idToken]);

  // create & update positions
  useEffect(() => {
    if (shouldDisplayAllPositionsOnChart) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolPositionsMetrics.forEach(metric => {
            const pl = calcPositionPl(metric);

            const currentPl = getInstrumentPl(pl);
            const positionRef = positions.get(metric.positionId)?.ref;
            if (positionRef) {
              positionRef?.setText(currentPl.stringValue);
              positionRef?.setBodyTextColor(currentPl.textColor);
            } else {
              createPosition(metric.positionId);
            }
          });
        }
      });
    }
  }, [
    JSON.stringify(symbolPositionsMetrics),
    shouldDisplayAllPositionsOnChart,
  ]);

  // delete position
  useEffect(() => {
    Array.from(positions.values()).forEach((position: IPosition) => {
      const matchedPosition =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(
          position.key
        );
      if (!matchedPosition) {
        positions.get(position.key)?.ref?.remove();
        positions.delete(position.key);
      }
    });
  }, [positionsMetrics.length]);

  useEffect(() => {
    if (!shouldDisplayAllPositionsOnChart) {
      clearPositions();
    }
  }, [shouldDisplayAllPositionsOnChart]);
};
