import React from 'react';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { Typography } from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import {
  addSeparatorToIntegerNumber,
  fixToTwoDigitAfterDot,
  formatAccountCurrencyValue,
} from '@trader/utils';

import * as Styled from './styled';

export const PerformanceSummary: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const backTesting = store.pages.muliBands.backTesting;
  const takeProfit = backTesting?.summary?.totalProfit || 0;
  const avgProfit = backTesting?.summary?.avgProfit || 0;
  const maxProfit = backTesting?.summary?.maxProfit || 0;
  const numberOfTrades = backTesting?.summary?.numberOfTrades || 0;
  const currencySymbol = store.user.tradingAccount?.currencySymbol || '';

  return (
    <Stack direction='row' spacing={4} p={3}>
      <Styled.Summary $profit={takeProfit}>
        <Typography>{translate('COMMON.LABELS.TAKE_PROFIT')}</Typography>
        <Typography>
          {formatAccountCurrencyValue(takeProfit, currencySymbol)}
        </Typography>
      </Styled.Summary>
      <Styled.Summary $profit={avgProfit}>
        <Typography>{translate('MULI_BANDS.AVERAGE_PROFIT')}</Typography>
        <Typography>
          {formatAccountCurrencyValue(avgProfit, currencySymbol)}
        </Typography>
      </Styled.Summary>
      <Styled.Summary $profit={maxProfit}>
        <Typography>{translate('MULI_BANDS.MAX_PROFIT')}</Typography>
        <Typography>
          {formatAccountCurrencyValue(maxProfit, currencySymbol)}
        </Typography>
      </Styled.Summary>
      <Styled.Summary>
        <Typography>{translate('MULI_BANDS.NUMBER_OF_TRADES')}</Typography>
        <Typography>
          {addSeparatorToIntegerNumber(+fixToTwoDigitAfterDot(numberOfTrades))}
        </Typography>
      </Styled.Summary>
    </Stack>
  );
});
