import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Content = styled(Wrapper)`
  ${() => css`
    align-items: center;
    width: 150px;
    margin-left: 20px;
  `};
`;
