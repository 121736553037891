import { api, IInstrumentSpecificationBE } from '@trader/api';
import { IMessage } from '@trader/types';
import { formatByPipSize } from '@trader/utils';
import { ILibrarySymbolInfo } from './types';
import {
  ErrorCallback,
  HistoryCallback,
  PeriodParams,
  ResolutionString,
  SubscribeBarsCallback,
} from './charting_library';
import {
  rootStore,
  TInstrumentEntity,
  TTradingAccountEntity,
} from '@trader/store';
import {
  getAccountTypeForConnection,
  productId,
  defaultPipSize,
  emptyFn,
  convertTimeToUtc,
  isMuliBandsEnv,
  BANDS,
} from '@trader/constants';
import { ITCStydy } from './utils/getTradingCentral';
import { HubConnection } from '@microsoft/signalr';
import { resetChartData } from '@trader/containers';
import { getTradingHoliday, getTradingSession } from './utils';

export const resolutionObject = {
  '1': 'Minute1',
  '2': 'Minute2',
  '5': 'Minute5',
  '15': 'Minute15',
  '30': 'Minute30',
  '60': 'Hour1',
  '120': 'Hour2',
  '240': 'Hour4',
  '1D': 'Day1',
  '1W': 'Week1',
  '1M': 'Month1',
};

export const invertedResolutionObject = {
  Minute1: '1',
  Minute2: '2',
  Minute5: '5',
  Minute15: '15',
  Minute30: '30',
  Hour1: '60',
  Hour2: '120',
  Hour4: '240',
  Day1: '1D',
  Week1: '1W',
  Month1: '1M',
};

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: Object.keys(
    resolutionObject
  ) as Array<ResolutionString>,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [{ value: '' }],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ value: '' }],
};
const twoMin = 120000;
let shouldTriggerUpdate = false;
let lastBarVwap = 0;
let firstBar = 0;
const lastBar = {
  time: 0,
  low: 0,
  high: 0,
  open: 0,
  close: 0,
  volume: 0,
};

export default (
  subscribe: (
    send: (connection: HubConnection | null) => Promise<void>,
    onSubscribeMessage?: (message: IMessage) => void
  ) => Promise<void>,
  getSymbolData: (symbol: string) => Promise<IInstrumentSpecificationBE>,
  getIndicators: (symbol: string) => Promise<ITCStydy>,
  prefetchedData: Pick<
    TInstrumentEntity,
    'pipSize' | 'description' | 'category' | 'close' | 'sessions'
  >,
  activeTradingAccount: TTradingAccountEntity,
  hasVolume?: boolean
) => ({
  onReady: (callback: (config: typeof configurationData) => void) => {
    setTimeout(() => callback(configurationData), 0);
  },
  searchSymbols: emptyFn,
  resolveSymbol: async (
    symbol: string,
    onSymbolResolvedCallback: (symbolInfo: ILibrarySymbolInfo) => void
  ) => {
    // do not trigger requests if reset data
    if (symbol === resetChartData) {
      return;
    }

    try {
      const resInfo = getSymbolData(symbol);
      const resIndicators = getIndicators(symbol);
      const [data, indicators] = await Promise.all([resInfo, resIndicators]);

      const pricescale = (data?.pipSize || defaultPipSize)
        .toString()
        .split('.')
        .join('')
        .split('')
        .reverse()
        .join('');

      const symbolInfo: ILibrarySymbolInfo = {
        ticker: symbol as string,
        name: symbol as string,
        description: (data?.description as string) || '',
        pipSize: data?.pipSize || defaultPipSize,
        closePrice: data?.close || prefetchedData.close,
        tradingCentral: indicators.prices || [],
        supported_resolutions: configurationData.supported_resolutions,
        type: data?.category || '',
        session: getTradingSession(data?.sessions),
        session_holidays: getTradingHoliday(data?.sessions),
        timezone: 'Etc/UTC',
        exchange: '',
        minmov: 1,
        pricescale: Number(pricescale),
        has_intraday: true,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        volume_precision: 2,
        data_status: 'streaming',
      };
      lastBarVwap = 0;
      setTimeout(() => {
        onSymbolResolvedCallback(symbolInfo);
      }, 0);
    } catch (e) {
      const symbolInfo: ILibrarySymbolInfo = {
        ticker: symbol as string,
        name: symbol as string,
        description: (prefetchedData?.description as string) || '',
        pipSize: prefetchedData?.pipSize || defaultPipSize,
        closePrice: prefetchedData?.close || 0,
        tradingCentral: [],
        supported_resolutions: configurationData.supported_resolutions,
        type: prefetchedData?.category || '',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: '',
        minmov: 1,
        pricescale: 1000,
        has_intraday: true,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        volume_precision: 2,
        data_status: 'streaming',
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbolInfo);
      }, 0);
    }
  },

  getBars: async (
    symbolInfo: ILibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback
  ) => {
    try {
      const response = await api.Historical.getCandleBars(
        symbolInfo.name,
        resolutionObject[resolution],
        periodParams.firstDataRequest ? 0 : firstBar
      );

      if (response?.length === 0) {
        onHistoryCallback([], { noData: true });
        return;
      }

      // eslint-disable-next-line require-atomic-updates
      firstBar = response[0].t;

      if (periodParams.firstDataRequest) {
        lastBar.low = response[response.length - 1].l;
        lastBar.close = response[response.length - 1].c;
        lastBar.high = response[response.length - 1].h;
        lastBar.open = response[response.length - 1].o;
        lastBar.volume = response[response.length - 1].v;
        lastBar.time = response[response.length - 1].t * convertTimeToUtc;

        onHistoryCallback(
          response.map((bar, index) => ({
            time: bar.t * convertTimeToUtc,
            low: bar.l,
            high: bar.h,
            open: bar.o,
            close: bar.c,
            volume: index + 1 === response.length ? 0 : bar.v,
          })),
          { noData: false }
        );
      } else {
        // fetching more bars while scrolling
        onHistoryCallback(
          response.map(bar => ({
            time: bar.t * convertTimeToUtc,
            low: bar.l,
            high: bar.h,
            open: bar.o,
            close: bar.c,
            volume: bar.v,
          })),
          { noData: false }
        );
      }
    } catch (error) {
      onErrorCallback(error as string);
    }
  },

  subscribeBars: (
    symbolInfo: ILibrarySymbolInfo,
    _resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    _tick: string
  ) => {
    subscribe(
      async connection => {
        await connection?.send(
          'SubscribeOnQuote',
          symbolInfo.name,
          1,
          productId[activeTradingAccount.product],
          activeTradingAccount.platformLogin,
          getAccountTypeForConnection[activeTradingAccount.accountType]
        );
      },
      (message: IMessage) => {
        if (message.s === symbolInfo.name) {
          const price = message.p
            ? formatByPipSize(message.p, symbolInfo.pipSize)
            : null;

          const barTime = new Date(message.t);
          if (hasVolume) {
            barTime.setSeconds(0, 0);
            // building new bar
            if (barTime.getTime() - lastBar.time >= twoMin) {
              const lastBarTime = new Date(lastBar.time);
              // eslint-disable-next-line no-magic-numbers
              lastBarTime.setMinutes(lastBarTime.getMinutes() + 1, 59, 999);
              shouldTriggerUpdate = true;

              onRealtimeCallback({
                ...lastBar,
                time: lastBarTime.getTime(),
              });

              if (price) {
                lastBar.low = Number(price);
                lastBar.close = Number(price);
                lastBar.high = Number(price);
                lastBar.open = Number(price);
              }
              lastBar.volume = 0;
              lastBar.time = barTime.getTime();
            } else {
              if (price) {
                onRealtimeCallback({
                  open: Number(price),
                  high: Number(price),
                  low: Number(price),
                  close: Number(price),
                  volume: 0,
                  time: message.t,
                });
                lastBar.low = Number(price);
                lastBar.close = Number(price);
                lastBar.high = Number(price);
                lastBar.open = Number(price);
              }
              lastBar.volume += message.v;

              if (isMuliBandsEnv) {
                rootStore.pages.muliBands.setMultiplier(new Date(message.t));
              }
              // trick for synchronization data with BE
              if (shouldTriggerUpdate) {
                const vwap = rootStore.pages.muliBands.strips.get(
                  BANDS.RoundedVwap.id
                );
                if (vwap && vwap.value) {
                  if (lastBarVwap !== vwap.value) {
                    rootStore.pages.muliBands.onRecalculate();
                  }
                  lastBarVwap = vwap.value;
                }

                shouldTriggerUpdate = false;
              }
            }
          } else {
            if (price) {
              onRealtimeCallback({
                open: Number(price),
                high: Number(price),
                low: Number(price),
                close: Number(price),
                time: message.t,
              });
            }
          }
        }
      }
    );
  },
  unsubscribeBars: emptyFn,
});
