import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  useDebounce,
  useGetTradingDefaultFormValues,
  usePrevious,
  useTriggerTradingFormFields,
  useUpdatePricesInRealTime,
  useUpdateStopLossRealTime,
  useUpdateTakeProfitRealTime,
} from '@trader/hooks';
import {
  TOrderMetricEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import { oneSecond } from '@trader/constants';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';

interface IRealTimeTriggering extends ICommonOrderType {
  children: React.ReactNode;
}

export const TradingRealTimeUpdatesInitializer: React.FC<IRealTimeTriggering> =
  observer(({ type, children }) => {
    const store = useMst();
    const { getValues, reset, setValue, formState } =
      useFormContext<IInitialTradingFormValues>();

    const trading = store.trading.getTrading(type);

    const orderId = store.ui.modal.options.get('orderId');
    const orderMetric =
      store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
    const pendingPrice = orderMetric?.stopPrice || orderMetric?.limitPrice;

    const positionId = store.ui.modal.options.get('positionId');
    const positionMetric =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);
    const positionTP = positionMetric?.takeProfit?.limitPrice || undefined;
    const positionSL = positionMetric?.stopLoss?.stopPrice || undefined;
    const positionAmount = positionMetric?.quantity || undefined;

    const defaultFormValues = useGetTradingDefaultFormValues(type, {
      symbol: (trading.instrument && trading.instrument.symbol) || '',
      side: orderMetric?.side || positionMetric?.side || trading.side,
      takeProfit: orderId ? orderMetric?.takeProfit : positionTP,
      stopLoss: orderId ? orderMetric?.stopLoss : positionSL,
      pendingPrice: orderId ? pendingPrice : undefined,
      amount: orderId ? orderMetric?.quantity : positionAmount,
    });

    const { triggerAllPriceRelatedFields, triggerPriceField } =
      useTriggerTradingFormFields();

    useUpdatePricesInRealTime(type);
    useUpdateTakeProfitRealTime(type);
    useUpdateStopLossRealTime(type);

    const amount = getValues().amount;
    const price = getValues().price;
    const side = getValues().side;

    const prevAmount = usePrevious(amount);
    const prevPrice = usePrevious(price);
    const prevSide = usePrevious(trading.side);

    useDebounce(
      () => {
        const isSameInputsAfterFundsErrorAppeared =
          amount === prevAmount &&
          price === prevPrice &&
          trading.isRequiredOpenCostError;

        // do not trigger api method if error exists and inputs aren't changed;
        if (!isSameInputsAfterFundsErrorAppeared && !!side) {
          trading.getRequiredOpenCostAsync.run({
            amount,
            price,
            side,
          });
        }
      },
      [amount, price, side],
      oneSecond
    );

    useEffect(() => {
      trading.updateOrderType('Market');
      reset({ ...defaultFormValues, orderType: 'Market' });
      triggerAllPriceRelatedFields();
      triggerPriceField();
    }, [trading.instrument?.symbol]);

    useEffect(() => {
      setValue('side', trading.side);
      triggerAllPriceRelatedFields();
    }, [trading.side]);

    useEffect(() => {
      if (
        formState.isDirty ||
        getValues().isStopLoss ||
        getValues().isTakeProfit ||
        getValues().orderType !== 'Market'
      ) {
        setValue('side', trading.side || 'Buy');
        trading.updateSide(trading.side || 'Buy');
        triggerAllPriceRelatedFields();
      }
    }, [
      formState.isDirty,
      getValues().isStopLoss,
      getValues().isTakeProfit,
      getValues().orderType,
    ]);

    /**
     * Do the initial call of the required open cost request
     * when the side is selected.
     */
    useEffect(() => {
      if (trading.side && prevSide === null) {
        trading.getRequiredOpenCostAsync.run({
          amount: amount || String(defaultFormValues.amount),
          price,
          side,
        });
      }
    }, [trading.side]);

    return children;
  });
